.blog-article h2 {
    @apply lg:text-2xl text-xl font-bold mb-3 mt-8;
}

.blog-article h3 {
    @apply lg:text-xl text-lg font-bold;
}

.blog-article h4 {
    @apply lg:text-lg text-base font-bold;
}

.blog-article h1,
.blog-article h2,
.blog-article h3,
.blog-article h4,
.blog-article h5,
.blog-article h6 {
    @apply text-gray-700;
}

.blog-article p,
.blog-article a,
.blog-article span,
.blog-article i,
.blog-article b,
.blog-article dt,
.blog-article dd,
.blog-article li {
    @apply text-gray-600 text-base;
}
